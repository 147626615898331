import {useContext} from "react";
import {stateContext} from "../../contexts/stateContext";

const Header = () => {

    const contextData = useContext(stateContext);

    return (
        <header className="flex justify-between items-center h-[11%] px-2 mt-4 w-full max-w-[768px]">
            <div className="flex flex-col items-center gap-2 w-full">
                <span>Level {contextData.state.level}</span>
                <progress className="progress progress-success w-full" value={contextData.state.levelProcess} max="100"></progress>
            </div>
        </header>
    );
}

export default Header;
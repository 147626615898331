import { useContext } from "react";
import { stateContext } from "../../contexts/stateContext";

const Process = () => {

    const contextData = useContext(stateContext);

    const process = () => {
        return (contextData.state.energyLimit / contextData.state.maxEnergy) * 100;
    }

    return (
        <div className="flex flex-col justify-center items-center gap-1 w-full">
            <span className="font-semibold">{contextData.state.energyLimit} <span
                className="font-medium text-white/70">/ {contextData.state.maxEnergy}</span></span>
            <progress className="progress progress-success w-full" value={process()} max="100"></progress>
        </div>
    );

}

export default Process;
import './assets/css/app.css';

import ReactDOM from 'react-dom/client';
import App from "./app";
import React from "react";

// const params = new URLSearchParams(window.location?.search ? window.location?.search : "");
// const data = params.get('data');

// if (!data && window.location.pathname != '/404') {
//     window.location.href = '/404';
// }     

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
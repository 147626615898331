import './home.css';
import Navbar from "../layouts/navbar";
import Main from "../layouts/main";
import { useContext } from "react";
import tap from "../../assets/images/tap.png";
import { stateContext } from "../../contexts/stateContext";
import Coin from "../layouts/coin";
import Header from '../layouts/header';

const Task = () => {

    const contextData = useContext(stateContext);

    const handleClaim = (event) => {
        const elem = event.target
        const itemID = elem.getAttribute('data-id')
        let data = contextData.state.levels.filter(item => item.id === Number(itemID))[0];
        if (data.isClaimed) {
            data.isClaimed = false;
            data.claimed = true;
            for (let i = 0; i < data.getEnergy / 20; i++) {
                setTimeout(() => {
                    contextData.setState(state => ({
                        ...state,
                        energy: state.energy + 20
                    }))
                }, 1)
            }
        }
    }

    return (
        <>
            <Header />
            <Main>
                <div data-aos="fade-right" className="w-full flex flex-col items-center gap-2">
                    <span className='text-xl'>ثواب های شما :</span>
                    <span className="text-4xl font-semibold">{contextData.getEnergy()}</span>
                    <hr className="my-4 bg-white w-full" />
                </div>
                <div data-aos="fade-right" className="w-full flex flex-col gap-4 overflow-auto px-1">
                    {
                        contextData.state.levels?.map((item, index) => {
                            return (
                                <div key={index}
                                    className="flex justify-between items-center bg-green-900 w-full p-4 rounded-xl box-border">
                                    <div className="flex justify-center items-center gap-4">
                                        <div className="flex flex-col gap-1">
                                            <span className="font-medium text-md">رسیدن به لول {item.level}</span>
                                            <div className="flex items-center gap-2">
                                                <img className="h-4" src={tap} />
                                                <span className="text-white/70">{contextData.getEnergy(item.getEnergy)} ثواب رایگان</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex">
                                        {
                                            item.isClaimed ? (
                                                <button className="bg-primary px-4 py-2 rounded-lg" data-id={item.id} onClick={handleClaim}>Claim</button>
                                            ) : (
                                                <button className="bg-white/10 px-4 py-2 rounded-lg" disabled={true}>{item.claimed ? "Claimed" : "Claim"}</button>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="w-full flex justify-center items-center">
                        <Coin />
                    </div>
                </div>
                <Navbar />
            </Main>
        </>
    );
}

export default Task;
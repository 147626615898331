import './home.css';
import Navbar from "../layouts/navbar";
import Main from "../layouts/main";
import Header from "../layouts/header";
import { useContext } from "react";
import { stateContext } from "../../contexts/stateContext";


const Status = () => {

    const contextData = useContext(stateContext);

    return (
        <>
            <Header />
            <Main>
                <div data-aos="fade-left" className="flex flex-col justify-center items-center gap-10 h-[89%] w-full px-4">

                    <div className="flex flex-col justify-center items-center gap-2">
                        <span className="text-lg">تعداد ثواب های شما :</span>
                        <span className="text-3xl font-bold">{contextData.getEnergy(contextData.state.energy)}</span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-2">
                        <span className="text-lg">تعداد کل ثواب های مسلمانان :</span>
                        <span className="text-3xl font-bold">{contextData.getEnergy(contextData.state.totalShare)}</span>
                    </div>

                    <div className="flex flex-col justify-center items-center gap-2">
                        <span className="text-lg">تعداد مسلمامان :</span>
                        <span className="text-3xl font-bold">{contextData.getEnergy(contextData.state.totalPlayers)}</span>
                    </div>

                </div>
                <Navbar />
            </Main>
        </>
    );
}

export default Status;
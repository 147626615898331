import { useEffect, useState } from "react";
import Home from "./components/pages/home";
import { stateContext } from "./contexts/stateContext";
import { createBrowserRouter, RouterProvider, redirect } from "react-router-dom";
import Task from "./components/pages/task";
import Status from "./components/pages/status";
import AOS from "aos";
import "aos/dist/aos.css";

AOS.init();

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/task",
        element: <Task />,
    },
    {
        path: "/status",
        element: <Status />,
    },
    {
        path: "*",
        element: "",
    },
]);

const App = () => {

    const [state, setState] = useState({
        energy: localStorage.getItem('energy') ? Number(localStorage.getItem('energy')) : 5000 ,
        maxEnergy: 10000,
        energyLimit: localStorage.getItem('energyLimit') ? Number(localStorage.getItem('energyLimit')) : 10000,
        multiTap: 1,
        speed: 1,
        level: localStorage.getItem('level') ? Number(localStorage.getItem('level')) : 0,
        levelProcess: localStorage.getItem('levelProcess') ? Number(localStorage.getItem('levelProcess')) : 0,
        levels: localStorage.getItem('levels') ? JSON.parse(localStorage.getItem('levels')) : [
            { id: 1, level: 1, energy: 1000, getEnergy: 500, claimed: false, isClaimed: false },
            { id: 2, level: 2, energy: 5000, getEnergy: 1000, claimed: false, isClaimed: false },
            { id: 3, level: 3, energy: 20000, getEnergy: 5000, claimed: false, isClaimed: false },
            { id: 4, level: 4, energy: 50000, getEnergy: 20000, claimed: false, isClaimed: false },
            { id: 5, level: 5, energy: 100000, getEnergy: 40000, claimed: false, isClaimed: false },
            { id: 6, level: 6, energy: 2000000, getEnergy: 70000, claimed: false, isClaimed: false },
            { id: 7, level: 7, energy: 5000000, getEnergy: 130000, claimed: false, isClaimed: false },
            { id: 8, level: 8, energy: 10000000, getEnergy: 230000, claimed: false, isClaimed: false },
            { id: 9, level: 9, energy: 20000000, getEnergy: 500000, claimed: false, isClaimed: false },
            { id: 10, level: 10, energy: 50000000, getEnergy: 1000000, claimed: false, isClaimed: false },
        ],
        totalShare: 565415406,
        totalPlayers: 215615,
    });

    useEffect(() => {
        localStorage.setItem('energy', state.energy);
    }, [state.energy])

    useEffect(() => {
        localStorage.setItem('level', state.level);
    }, [state.level])

    useEffect(() => {
        localStorage.setItem('levelProcess', state.levelProcess);
    }, [state.levelProcess])

    useEffect(() => {
        localStorage.setItem('levels', JSON.stringify(state.levels));
    }, [state.levels])

    useEffect(() => {
        localStorage.setItem('energyLimit', JSON.stringify(state.energyLimit));
    }, [state.energyLimit])

    const getEnergy = (energy = false) => {
        energy ? energy = energy : energy = state.energy;
        const coin = String(energy);
        let reversed = coin.split('').reverse().join('');
        let withDots = reversed.match(/.{1,3}/g).join('.');
        return withDots.split('').reverse().join('');
    }

    const handleLevel = () => {
        let items = state.levels.filter(item => {
            return state.energy < item.energy;
        });
        let levelItem = items[0] ? items[0] : state.level ;
        setState(state => ({
            ...state,
            level: levelItem.level,
            levelProcess: (state.energy / levelItem.energy) * 100
        }));
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setState(prevData => ({
                ...prevData,
                energyLimit: prevData.energyLimit < prevData.maxEnergy ? prevData.energyLimit + prevData.speed : prevData.energyLimit
            }));
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const newItems = []
        state.levels.forEach(item => {
            if (state.level >= item.level && !item.claimed) {
                newItems.push({
                    ...item,
                    isClaimed: true
                });
            } else {
                newItems.push(item)
            }
        })
        state.levels = newItems;
        handleLevel()
        return () => null;
    }, [state.energy]);

    useEffect(() => {
        handleLevel()
    }, [state.energy]);


    return (
        <stateContext.Provider
            value={{ state: state, setState: setState, getEnergy: getEnergy, handleLevel: handleLevel }}>
            <RouterProvider router={router} />
        </stateContext.Provider>
    );
}

export default App;
import { NavLink } from "react-router-dom";
import taskImage from "../../assets/images/task.png";
import tapImage from "../../assets/images/tap.png";
import statusImage from "../../assets/images/status.png";
import "./navbar.css";


const Navbar = () => {
    return (
        <div className="w-full grid grid-cols-3 gap-1.5 h-20">
            <NavLink to="/status"
                className="flex flex-col justify-center items-center gap-1 bg-green-900 h-20 rounded-2xl cursor-pointer box-border">
                <img src={statusImage} width={25} />
                <span>مسلمان</span>
            </NavLink>
            <NavLink to="/"
                className="flex flex-col justify-center items-center gap-1 bg-green-900 h-20 rounded-2xl cursor-pointer box-border">
                <img src={tapImage} width={25} />
                <span>دریافت ثواب</span>
            </NavLink>
            <NavLink to="/task"
                className="flex flex-col justify-center items-center gap-1 bg-green-900 h-20 rounded-2xl cursor-pointer box-border">
                <img src={taskImage} width={25} />
                <span>ثواب رایگان</span>
            </NavLink>
        </div>
    );
}

export default Navbar;
import './home.css';
import {stateContext} from "../../contexts/stateContext";
import {useContext} from "react";
import Navbar from "../layouts/navbar";
import Header from "../layouts/header";
import Coin from "../layouts/coin";
import Process from "../layouts/process";
import Main from '../layouts/main';

const Home = () => {

    const contextData = useContext(stateContext);

    return (
        <>
            <Header/>
            <Main>
                <div data-aos="fade-down" className="w-full h-[85%] flex flex-col justify-between items-center">
                    <div className="flex flex-col items-center gap-2">
                        <span className='text-xl'>ثواب های شما :</span>
                        <span className="text-4xl font-semibold">{contextData.getEnergy()}</span>
                    </div>
                    <Coin/>
                    <Process/>
                </div>
                <Navbar/>
            </Main>
        </>
    );
}

export default Home;
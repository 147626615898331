import coinImage from "../../assets/images/coin.png";
import {useContext} from "react";
import {stateContext} from "../../contexts/stateContext";

const Coin = () => {

    const contextData = useContext(stateContext);

    const handleCoinClick = (event) => {

        if ((contextData.state.energyLimit - contextData.state.multiTap) < 0) return;

        contextData.setState(state => ({
            ...state,
            energy: state.energy + state.multiTap,
            energyLimit: state.energyLimit - state.multiTap
        }))

        const elem = document.createElement("span");
        elem.classList.add("active");
        elem.textContent = "سبحان الله";
        elem.style.cssText = `top: ${event.clientY - 25}px; left: ${event.clientX}px;`;
        document.querySelector("main").append(elem);
        document.querySelector("#coin").classList.add("active");
        setTimeout(() => {
            document.querySelector("#coin").classList.remove("active");
            elem.style.cssText = `top: ${event.clientY - 200}px; left: ${event.clientX}px;`;
            elem.style.opacity = .2;
        }, 100);
        setTimeout(() => {
            elem.remove();
        }, 2000);

        contextData.handleLevel();

    }

    return (
        <div className="flex w-[400px] max-w-[80%] cursor-pointer" id="coin" onClick={handleCoinClick}>
            <img src={coinImage} className='w-full'/>
        </div>
    );
}

export default Coin;